<script lang="ts" setup>

</script>

<template lang="pug">
.official-cooperation.max-w-4xl.mx-auto
  .flex.gap-4.mx-auto.flex-col.items-start(class="md:flex-row md:items-center md:gap-20")
    img(
      src="/images/logo-offizielles-mitglied-kba.svg"
      alt="Icon: Deutschland-Adler"
      loading="lazy"
    ).h-14.w-auto
    .content
      p.text-sm.mt-2.
        Als offizielles Mitglied stehen wir in engem Austausch mit dem Kraftfahrt-Bundesamt (KBA)
        und dem fachaufsichtsführenden Bundesministerium für Digitales und Verkehr (BMDV) und
        unterstützen die Behörden dabei, die digitalen Zulassungsprozesse in Deutschland voranzubringen.
</template>

<style lang="sass">

</style>
